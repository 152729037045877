import { Component, OnInit, Input } from '@angular/core';
import { ExamService } from '../services/exam.service';

@Component({
  selector: 'jdg-certification-badges',
  templateUrl: './certification-badges.component.html',
  styleUrls: ['./certification-badges.component.css']
})
export class CertificationBadgesComponent implements OnInit {

  @Input() exams: any[];
  certificaitons: any[] = [];
  constructor() { }

  ngOnInit() { 
    this.certificaitons = this.exams.filter(exam => {
      return exam.passed;
    }).map(exam => {
      return exam.exam.certification;
    });
    
  }

}
