import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [ConfirmationService]
})
export class NavbarComponent implements OnInit {

  isLoggedIn: boolean = false;
  language:string;
  user: any;
  subscriptions: Subscription[] = [];

  constructor(private loginService: LoginService, private confirmationService: ConfirmationService, private router: Router) { }

  ngOnInit() {
    this.loadLanguage();
    this.subscriptions.push(this.loginService.getLoggedInUser().subscribe(resp => this.updateOptions(resp)));
  }

  updateOptions(loginResponse: any): void {
    if (loginResponse.status === 'success') {
      this.isLoggedIn = true;
      this.user = loginResponse.data;
    } else {
      this.isLoggedIn = false;
    }
  }

  logout() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to logout?',
      accept: () => {
        this.loginService.logout();
        this.router.navigate(['/login']);
      }
    });
  }

  swapLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.updateUILanguage(language);
    this.reloadPage(); // test
  }

  reloadPage(): void {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    // location.reload();
  }

  loadLanguage(): void {
    let languageCode = localStorage.getItem('language');

    if (!languageCode) {
      languageCode = 'en';
      localStorage.setItem('language', languageCode);
    }

    this.updateUILanguage(languageCode);
  }

  updateUILanguage(languageCode: string): void {
    switch (languageCode) {
      case 'en':
        this.language = 'English';
        break;
      case 'es':
        this.language = 'Español';
        break;
      case 'pt':
        this.language = 'Português';
        break;
      default:
        this.language = 'English';
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
