import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

user: any = '';
private isLoggedIn: boolean = false;
private subscription: Subscription;

constructor(private http: HttpClient, private router: Router) { }

doLogin(user: any): Observable<any> {
  return this.http.post<any>(API_URL + '/users/login', user);
}

persistLogin(response: any): void {
  const token = response.token;

  this.isLoggedIn = true;

  localStorage.setItem('token', token);
}

getLoggedInUser(): Observable<any> {
  if (this.user === '') {
    this.subscription = this.http.get<any>(API_URL + '/users/logged_in').subscribe(userResponse => this.persistUser(userResponse));
    return this.http.get<any>(API_URL + '/users/logged_in');
  } else {
    return of(this.user);
  }
}

persistUser(userResponse: any): void {
  this.user = userResponse;
  this.isLoggedIn = true;
  this.subscription.unsubscribe();
}

logout(): void {
  localStorage.removeItem('token');
  this.user = '';
  this.isLoggedIn = false;
}

loggedIn(): boolean {
  return this.isLoggedIn;
}

async hasPermission(permission: string): Promise<boolean> {
  const userResponse = await this.getLoggedInUser().toPromise();

  if (userResponse.status === 'fail') {
    return false;
  } else {
    if (permission === 'logged_in') {
      return true;
    }
      
    return userResponse.data.permission[permission];
  }
}

}
