import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { ExamLandingComponent } from './exam-landing/exam-landing.component';
import { ExamComponent } from './exam/exam.component';
import { SignupComponent } from './signup/signup.component';
import { GradeComponent } from './grade/grade.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { AuthGuard } from './auth.guard';
import { ManageResourcesComponent } from './manage-resources/manage-resources.component';
import { ResourceCreateComponent } from './resource-create/resource-create.component';
import { CreateExamComponent } from './create-exam/create-exam.component';
import { EditExamComponent } from './edit-exam/edit-exam.component';
import { PublicJudgesComponent } from './public-judges/public-judges.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CategoriesComponent } from './categories/categories.component';
import { CreateNewsComponent } from './create-news/create-news.component';


const routes: Routes = [
  // unguarded routes
  {path : '', component: LandingComponent, pathMatch : 'full'},
  {path : 'login', component: LoginComponent, pathMatch : 'full'},
  {path : 'exams', component: ExamLandingComponent, pathMatch : 'full'},
  {path : 'exams/results', component: GradeComponent, pathMatch : 'full'},
  {path : 'exam/:id', component: ExamComponent },
  {path : 'signup', component: SignupComponent},
  {path : 'roster', component: PublicJudgesComponent},

  // auth guarded routes
  {path : 'news', component: CreateNewsComponent, canActivate: [AuthGuard], data: {permission : 'edit_news'}},
  {path : 'resources', component: CategoriesComponent, canActivate: [AuthGuard], data: {permission : 'logged_in'}},
  {path : 'resources/:id', component: ManageResourcesComponent, canActivate: [AuthGuard], data: {permission : 'logged_in'}},
  {path : 'users/view/:id', component: UserProfileComponent, canActivate: [AuthGuard], data: {permission : 'logged_in'}},
  {path : 'manage/configuration', component: ConfigurationComponent, canActivate: [AuthGuard], data: {permission : 'edit_configuration'}},
  {path : 'create/resource', component: ResourceCreateComponent, canActivate: [AuthGuard], data: {permission : 'edit_resources'}},
  {path : 'create/exam', component: CreateExamComponent, canActivate: [AuthGuard], data: {permission : 'edit_exams'}},
  {path : 'edit/exam/:id', component: EditExamComponent, canActivate: [AuthGuard], data: {permission : 'edit_exams'}},


  //wildcard catch all redirect to dashboard
  {path : '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
