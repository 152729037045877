import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jdg-resource-landing',
  templateUrl: './resource-landing.component.html',
  styleUrls: ['./resource-landing.component.css']
})
export class ResourceLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
