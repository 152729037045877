import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ExamComponent } from './exam/exam.component';
import { LandingComponent } from './landing/landing.component';
import { ExamLandingComponent } from './exam-landing/exam-landing.component';
import { LoginComponent } from './login/login.component';
import { QuestionComponent } from './question/question.component';
import { SignupComponent } from './signup/signup.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './util/jwt-interceptor';

// PrimeNG imports
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GradeComponent } from './grade/grade.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { CardModule } from 'primeng/card';

import { fromEventPattern } from 'rxjs';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ConfigurationComponent } from './configuration/configuration.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CfgItemComponent } from './cfg-item/cfg-item.component';
import { PublicResourcesComponent } from './public-resources/public-resources.component';
import { ManageResourcesComponent } from './manage-resources/manage-resources.component';
import { ResourceWithUpdateComponent } from './resource-with-update/resource-with-update.component';
import { ResourceCreateComponent } from './resource-create/resource-create.component';
import { EditExamComponent } from './edit-exam/edit-exam.component';
import { CreateExamComponent } from './create-exam/create-exam.component';
import { QuestionEditorComponent } from './question-editor/question-editor.component';
import { AnswerEditorComponent } from './answer-editor/answer-editor.component';
import { PublicJudgesComponent } from './public-judges/public-judges.component';
import { CertificationBadgesComponent } from './certification-badges/certification-badges.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PermissionsViewerComponent } from './permissions-viewer/permissions-viewer.component';
import { JudgeProfileComponent } from './judge-profile/judge-profile.component';
import { FooterComponent } from './footer/footer.component';
import { CategoriesComponent } from './categories/categories.component';
import { ResourceLandingComponent } from './resource-landing/resource-landing.component';
import { BbcodePipe } from './bbcode.pipe';
import { PhonePipe } from './phone.pipe';
import { CreateNewsComponent } from './create-news/create-news.component';


@NgModule({
   declarations: [
      AppComponent,
      NavbarComponent,
      ExamComponent,
      LandingComponent,
      ExamLandingComponent,
      LoginComponent,
      QuestionComponent,
      SignupComponent,
      GradeComponent,
      HasPermissionDirective,
      ConfigurationComponent,
      WelcomeComponent,
      SidebarComponent,
      CfgItemComponent,
      PublicResourcesComponent,
      ManageResourcesComponent,
      ResourceWithUpdateComponent,
      ResourceCreateComponent,
      EditExamComponent,
      CreateExamComponent,
      QuestionEditorComponent,
      AnswerEditorComponent,
      PublicJudgesComponent,
      CertificationBadgesComponent,
      UserProfileComponent,
      PermissionsViewerComponent,
      JudgeProfileComponent,
      FooterComponent,
      CategoriesComponent,
      ResourceLandingComponent,
      BbcodePipe,
      PhonePipe,
      CreateNewsComponent
   ],
   imports: [
      ToastModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      FormsModule,
      HttpClientModule,
      ProgressSpinnerModule,
      ReactiveFormsModule,
      ConfirmDialogModule,
      EditorModule,
      FileUploadModule,
      CardModule,
   ],
   providers: [
      {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
