import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jdg-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  subscriptions: Subscription[] = [];
  configs: any[];
  isLoading: boolean = true;
  constructor(private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.subscriptions.push(this.configurationService.getFullConfig().subscribe(response => this.loadConfigurationItems(response)));

  }

  loadConfigurationItems(response: any): void {
    this.isLoading = false;
    if (response.status === 'success') {
      this.configs = response.data;
    }
  }
  
  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
