import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'jdg-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;
  categories: any[] = [];
  
  constructor(private resourceService: ResourceService) { }

  ngOnInit() {
    this.subscriptions.push(this.resourceService.getAllCategories().subscribe(response => this.loadPage(response)));
  }

  loadPage(response: any): void {
    	if (response.status === 'success') {
        this.categories = response.data;
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
      }
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
