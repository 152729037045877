import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamService } from '../services/exam.service';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'jdg-edit-exam',
  templateUrl: './edit-exam.component.html',
  styleUrls: ['./edit-exam.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EditExamComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = true;

  id: number;
  certification: string;
  name: string;
  description: string;
  passing_score: number;
  time_limit: number;
  active: boolean;
  questions: any[];

  // this is an array for adding questions to this exam
  unsavedQuestions: any[] = [];


  constructor(private activatedRoute: ActivatedRoute, private examService: ExamService, private messageService: MessageService, private router: Router, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.params.id) || 1;

    this.subscriptions.push(this.examService.getSortedExamById(this.id).subscribe(result => this.finishLoadingExam(result)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  finishLoadingExam(response: any): void {
    this.isLoading = false;
    if (response.status === 'success') {
      this.certification = response.data.certification;
      this.name = response.data.name;
      this.description = response.data.description;
      this.passing_score = response.data.passing_score;
      this.time_limit = (response.data.time_limit / 1000) / 60;
      this.questions = response.data.questions;
      this.active = response.data.active;
    }
    else {
  
    }
  }

  updateExam(): void {
    const exam = {
      certification : this.certification,
      name : this.name,
      description : this.description,
      passing_score : this.passing_score,
      time_limit : this.time_limit * 1000 * 60,
      active : this.active
    };
    this.isLoading = true;
    this.subscriptions.push(this.examService.updateExam(this.id, exam).subscribe(result => this.finishUpdate(result)));
  }

  finishUpdate(response: any): void {
    this.isLoading = false;
  	if (response.status === 'success') {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: response.message
      });
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Oops!',
        detail: response.message
      });
    }
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

  // reload exam
  reloadExam() {
    this.isLoading = true;
    this.unsavedQuestions = [];
    this.subscriptions.push(this.examService.getSortedExamById(this.id).subscribe(result => this.finishLoadingExam(result)));
  }

  // update after question update
  questionUpdated(question) {
    this.reloadExam();
  }

  addQuestion() {
    this.unsavedQuestions.push({});
  }

  deleteExam() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this exam? This cannot be undone.',
      accept: () => {
        this.subscriptions.push(this.examService.deleteExam(this.id).subscribe(response => this.finishDelete(response)));
      }
    })
  }


  finishDelete(response: any) {
    	if (response.status === 'success') {
        this.router.navigate(['/exams']);
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Oops!',
          detail: 'There was an error deleting the exam!'
        });
      }
  }

}
