import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'jdg-cfg-item',
  templateUrl: './cfg-item.component.html',
  styleUrls: ['./cfg-item.component.css'],
  providers: [MessageService]
})
export class CfgItemComponent implements OnInit {

  @Input() key: string;
  @Input() value: string;
  @Input() richEdit: boolean;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(private configurationService: ConfigurationService, private messageService: MessageService) { }

  ngOnInit() {
  }

  startUpdate(): void {
    this.isLoading = true;
    this.subscriptions.push(this.configurationService.updateConfiguration(this.key, this.value).subscribe(response => this.finishUpdate(response)));
  }

  finishUpdate(response: any): void {
    this.isLoading = false;
    if (response.status === 'success') {
      this.messageService.add({severity: 'success', summary: 'Updated ' + this.key, detail: response.message});
    } else {
      this.messageService.add({severity: 'error', summary: 'Oops', detail: response.message});
    }
  }

  useRichEdit(): boolean {
    return this.richEdit;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
