import { Component, OnInit } from '@angular/core';
import { ExamService } from '../services/exam.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'jdg-exam-landing',
  templateUrl: './exam-landing.component.html',
  styleUrls: ['./exam-landing.component.css']
})
export class ExamLandingComponent implements OnInit {

  exams: any = [];
  isLoading: boolean = true;

  // subscriptions for cleanup later
  private subscriptions: Subscription[] = [];

  constructor(private examService: ExamService, private loginService: LoginService) { }

  ngOnInit() {
    this.loginService.hasPermission('edit_exams').then( result => this.fetchExams(result))
  }

  private loadExams(exams: any) {
    this.isLoading = false;
    if (exams.status === 'success') {
      this.exams = exams.data;
    }
  }

  fetchExams(permission: boolean) {
    if (permission) {
      this.subscriptions.push(this.examService.getAllExams().subscribe( results => this.loadExams(results)));
    } else {
      const language = (localStorage.getItem('language')) ? localStorage.getItem('language') : 'en';
      this.subscriptions.push(this.examService.getAllActiveExams(language).subscribe( results => this.loadExams(results)));
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

  isEven(n: any) {
    return n % 2 === 0;
  }

}
