import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { LoginService } from '../services/login.service';

@Directive({
  selector: '[jdgHasPermission]'
})
export class HasPermissionDirective {

  @Input() jdgHasPermission: string;

  isVisible = false;
  canView = false;

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, private loginService: LoginService) { }

  async ngOnInit() {
    this.canView = await this.loginService.hasPermission(this.jdgHasPermission);

    if (this.canView) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }

}
