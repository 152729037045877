import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'jdg-permissions-viewer',
  templateUrl: './permissions-viewer.component.html',
  styleUrls: ['./permissions-viewer.component.css'],
  providers: [MessageService]
})
export class PermissionsViewerComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input() permissions:any;
  constructor(private userService: UserService, private messageService: MessageService) { }

  ngOnInit() {
    console.log(this.permissions);
  }

  updatePermissions(): void {
    this.subscriptions.push(this.userService.updatePermissions(this.permissions).subscribe(result => this.finishUpdate(result)));
  }

  finishUpdate(response: any) {
  	if (response.status === 'success') {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: response.message
      });
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Oops!',
        detail: response.message
      })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
