import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    const authed = (next.data.permission === 'logged_in') ? this.loginService.loggedIn() : await this.loginService.hasPermission(next.data.permission);
    const url = state.url;

    if (!authed) {
      if (!this.loginService.loggedIn()) {
        this.router.navigate(['/login'], {queryParams : {ref : url}});
      }
      else {
        this.router.navigate(['/']);
      }
    }

    return authed;
  }
  
}
