import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExamService } from '../services/exam.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'jdg-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.css'],
  providers: [MessageService]
})
export class CreateExamComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;

  name: string;
  certification: string;
  description: string;
  passing_score: number;
  time_limit: number;

  constructor(private examService: ExamService, private router: Router, private messageService: MessageService) { }

  ngOnInit() {
    this.readyToSubmit = true;
    this.isLoading = false;
  }

  createExam(): void {
    const exam = {
      name: this.name,
      certification: this.certification,
      description: this.description,
      passing_score: this.passing_score,
      time_limit: this.time_limit * 60 * 1000
    };
    this.isLoading = true;
    this.subscriptions.push(this.examService.createExam(exam).subscribe(result => this.finishCreate(result)));
  }

  finishCreate(response: any): void {
    	if (response.status === 'success') {
        this.router.navigate(['/edit/exam', response.data.id]);
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary : 'Oops!',
          detail: response.message
        });
      }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

}
