import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    try {
      const phoneNumber = parsePhoneNumber(value + '', args[0] as CountryCode);
      return phoneNumber.formatNational();
    } catch (error) {
      return value;
    }
  }

}
