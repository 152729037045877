import { Component, OnInit } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'jdg-resource-create',
  templateUrl: './resource-create.component.html',
  styleUrls: ['./resource-create.component.css'],
  providers: [MessageService]
})
export class ResourceCreateComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;

  url: string;
  title: string;
  signedUrl: string;
  description: string;
  categoryId: any;
  categories: any[] = [];

  constructor(private resourceService: ResourceService, private messageService: MessageService) { }

  ngOnInit() {
    this.subscriptions.push(this.resourceService.getAllCategories().subscribe(response => this.loadCategories(response)));
  }
  loadCategories(response: any): void {
    	if (response.status === 'success') {
        this.isLoading = false;
        this.categories = response.data;
      }
      else {
        this.isLoading = false;
      }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // file upload functions
  onUpload(event): void {
    this.isLoading = false;
    this.url = event.originalEvent.body.data.url;
    this.signedUrl = event.originalEvent.body.data.signedUrl;
    this.readyToSubmit = (this.url != '') && (this.signedUrl != '');
  }

  onBeginUpload(event): void {
    this.isLoading = true;
  }

  createResource(): void {
    const resource = {
      title : this.title,
      description : this.description,
      category : this.categoryId,
      url : this.url,
      signedUrl : this.signedUrl
    };
    console.log(resource);
    this.isLoading = true;
    this.subscriptions.push(this.resourceService.createResource(resource).subscribe(response => this.finishCreate(response)));
  }

  finishCreate(response: any): void {
    this.isLoading = false;
    if (response.status === 'success') {
      this.messageService.add({severity: 'success', summary: 'Created Resource', detail: response.message});
      this.title = '';
      this.url = '';
      this.signedUrl = '';
      this.description = '';
      this.readyToSubmit = false;
      console.log(response.data);
    } else {
      this.messageService.add({severity: 'error', summary: 'Oops', detail: response.message});
    }
  }


  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

}
