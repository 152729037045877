import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { identifierModuleUrl } from '@angular/compiler';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ExamService {


  constructor(private http: HttpClient, private router: Router) { }

  getExamById(id: number) {
    return this.http.get<any>(API_URL + '/exam/' + id);
  }

  getSortedExamById(id: number) {
    return this.http.get<any>(API_URL + '/exam/sorted/' + id);
  }

  getAllExams() {
    return this.http.get<any>(API_URL + '/exam');
  }

  getAllActiveExams(language: string) {
    return this.http.get<any>(API_URL + '/exam?active=true&language=' + language);
  }

  gradeExam(id: number, answer_key: any, time_started: number) {
    return this.http.post<any>(API_URL + '/exam/' + id, {answer_key, time_started});
  }

  getExamResults(examToken) {
    return this.http.get<any>(API_URL + '/exam/results/' + examToken);
  }

  createExam(exam) {
    return this.http.post<any>(API_URL + '/exam', exam);
  }

  updateExam(id, exam) {
    return this.http.post<any>(API_URL + '/exam/update/' + id, exam);
  }

  loadQuestionById(id) {
    return this.http.get<any>(API_URL + '/exam/question/' + id);
  }

  loadAnswerById(id) {
    return this.http.get<any>(API_URL + '/exam/answer/' + id);
  }

  updateQuestion(id, question) {
    return this.http.post<any>(API_URL + '/exam/question/' + id, question);
  }

  updateAnswer(id, answer) {
    return this.http.post<any>(API_URL + '/exam/answer/' + id, answer);
  }

  createQuestion(question) {
    return this.http.post<any>(API_URL + '/exam/create/question', question);
  }

  createAnswer(answer) {
    return this.http.post<any>(API_URL + '/exam/create/answer', answer);
  }

  deleteAnswer(id) {
    return this.http.delete<any>(API_URL + '/exam/answer/' + id);
  }

  deleteQuestion(id) {
    return this.http.delete<any>(API_URL + '/exam/question/' + id);
  }

  deleteExam(id: number) {
    return this.http.delete<any>(API_URL + '/exam/' + id);
  }
}
