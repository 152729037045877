import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private http: HttpClient) { }

  signupUser(user: any): Observable<any>{
    return this.http.post<any>(API_URL + '/users/signup', user);
  }

  getJudgeRoster(): Observable<any> {
    return this.http.get<any>(API_URL + '/users/roster?public=true');
  }

  getUsersPaginated(page: number): Observable<any> {
    return this.http.get<any>(API_URL + '/users/roster?page=' + page);
  }

  getUserById(id: number) {
    return this.http.get<any>(API_URL + '/users/' + id);
  }

  updatePermissions(permissions: any): Observable<any> {
    return this.http.post<any>(API_URL + '/users/permissions', permissions);
  }

}
