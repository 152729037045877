import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(private http: HttpClient) { }

  getAllResources() {
    return this.http.get<any>(API_URL + '/resources');
  }

  createResource(resource) {
    return this.http.post<any>(API_URL + '/resources', resource);
  }

  updateResource(id, resource) {
    return this.http.post<any>(API_URL + '/resources/update/' + id, resource);
  }

  getResourceById(id) {
    return this.http.get<any>(API_URL + '/resources/' + id);
  }

  deleteResourceById(id) {
    return this.http.delete(API_URL + '/resources/' + id);
  }

  getAllCategories() {
    return this.http.get<any>(API_URL + '/resources/categories');
  }

  getResourcesByCategory(id) {
    console.log(id);
    return this.http.get<any>(API_URL + '/resources/category/' + id);
  }
}
