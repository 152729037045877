import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'jdg-judge-profile',
  templateUrl: './judge-profile.component.html',
  styleUrls: ['./judge-profile.component.css']
})
export class JudgeProfileComponent implements OnInit {

  @Input() judge:any;
  constructor() { }

  ngOnInit() {
  }

  getLanguages(): string {
    const lang_array = [];

    if (this.judge.lang_en) {
      lang_array.push('English');
    }

    if (this.judge.lang_es) {
      lang_array.push('Spanish');
    }

    if (this.judge.lang_pt) {
      lang_array.push('Portuguese');
    }

    if (this.judge.lang_other && this.judge.lang_other.length > 0) {
      lang_array.push(this.judge.lang_other);
    }

    return lang_array.join(', ');
  }

  getExperience(): string {
    const tier_array = [];

    if (this.judge.tier_one) {
      tier_array.push('Tier 1');
    }

    if (this.judge.tier_two) {
      tier_array.push('Tier 2');
    }

    if (this.judge.tier_three) {
      tier_array.push('Tier 3');
    }

    return tier_array.join(', ');
  }

}
