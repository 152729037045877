import { Pipe, PipeTransform } from '@angular/core';
import { BBCodeParser } from './bbcode-parser';

@Pipe({
  name: 'bbcode'
})
export class BbcodePipe implements PipeTransform {


  transform(value: any, ...args: any[]): any {
    let parser = new BBCodeParser();
    return parser.parse(value);
  }

}
