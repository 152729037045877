import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'jdg-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;

  canView:boolean = false;
  showError:boolean = true;
  error: string;

  id: number = -1;
  initials: string;
  user: any;
  
  constructor(private loginService: LoginService, private activatedRoute: ActivatedRoute, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.params.id) || 1;
    this.loginService.hasPermission('edit_users').then( permission => {
      this.canView = permission;
      if (!permission)
        this.subscriptions.push(this.loginService.getLoggedInUser().subscribe(response => this.finishLoadingUser(response)));
      else
        this.subscriptions.push(this.userService.getUserById(this.id).subscribe(response => this.finishLoadingProfile(response)));
    });
    
  }

  finishLoadingProfile(response: any) {
    this.isLoading = false;
  	if (response.status === 'success') {
      console.log(response.data);
      this.user = response.data;
      this.initials = (this.user.firstName.slice(0,1) + this.user.lastName.slice(0,1)).toUpperCase();
    }
    else {
  
    }
  }

  finishLoadingUser(response: any): void {
  	if (response.status === 'success') {
      this.canView = this.id == response.data.id // this is the current logged in user let them see themselves
      if (this.canView) {
        this.subscriptions.push(this.userService.getUserById(this.id).subscribe(response => this.finishLoadingProfile(response)));
      } else {
        this.router.navigate(['/']);
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

}
