import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
       const password1 = AC.get('password').value; // to get value in input tag
       const password2 = AC.get('verifyPassword').value; // to get value in input tag
       if ( password1 !== password2) {
            // console.log('false');
            AC.get('verifyPassword').setErrors( { MatchPassword: true } );
        } else {
            // console.log('true');
            return null;
        }
    }
}