import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExamService } from '../services/exam.service';

@Component({
  selector: 'jdg-answer-editor',
  templateUrl: './answer-editor.component.html',
  styleUrls: ['./answer-editor.component.css']
})
export class AnswerEditorComponent implements OnInit {

  @Input() id: number = -1;
  @Input() questionId: number;
  @Output() updatedAnswer = new EventEmitter<any>();
  
  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;

  description: string;
  correct: boolean;

  create: boolean = true;
  
  constructor(private examService: ExamService) { }

  ngOnInit() {
    this.create = this.id == -1;
    if (!this.create) {
      this.subscriptions.push(this.examService.loadAnswerById(this.id).subscribe(result => this.finishLoading(result)));
    }
  }

  finishLoading(response: any): void {
  	if (response.status === 'success') {
      this.description = response.data.description;
      this.correct = response.data.correct;
    }
    else {
      
    }
  }

  createAnswer(): void {
    const answer = {
      description : this.description,
      correct : this.correct,
      questionId : this.questionId
    };
    this.subscriptions.push(this.examService.createAnswer(answer).subscribe(response => this.finishCreate(response)));
  }

  finishCreate(response: any): void {
  	if (response.status === 'success') {
      this.updatedAnswer.emit({status : 'success'});
    }
    else {
  
    }
  }

  updateAnswer(): void {
    const answer = {
      description : this.description,
      correct : this.correct
    };
    this.subscriptions.push(this.examService.updateAnswer(this.id, answer).subscribe(response => this.finishUpdate(response)));
  }

  finishUpdate(response: any): void {
  	if (response.status === 'success') {
      this.updatedAnswer.emit({status: 'success'});
    }
    else {
    }
  }

  deleteAnswer(): void {
    this.subscriptions.push(this.examService.deleteAnswer(this.id).subscribe(response => this.finishUpdate(response)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
