import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamService } from '../services/exam.service';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'jdg-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {

  questions: any[];
  examId: number;
  isLoading: boolean = true;
  exam: string;
  started: number;
  time_limit: number;

  answerKey = {}

  // subscriptions
  subscriptions: Subscription[] = [];


  constructor(private activatedRoute: ActivatedRoute, private examService: ExamService, private router: Router, private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.examId = parseInt(this.activatedRoute.snapshot.params.id) || 1;

    // load the exam
    this.subscriptions.push(this.examService.getExamById(this.examId).subscribe(response => this.loadExam(response)));
    
  }

  loadExam(examResponse) {
    this.isLoading = false;
    if (examResponse.status === 'success') {
      this.questions = examResponse.data.questions;
      this.exam = examResponse.data.name;
      this.started = Date.now();
      this.time_limit = Math.round(examResponse.data.time_limit / (1000 * 60));
    }
  }

  ngOnDestroy() {
    //unsubscribe
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  answered(answer) {
    this.answerKey[answer.question] = parseInt(answer.answer);
  }

  reportGrade(gradeResponse) {
    if (gradeResponse.status === 'success') {
      this.sharedDataService.set('examToken', gradeResponse.data.claim_token);
      if (gradeResponse.data.passed)
        this.router.navigate(['/signup']);
      else
        this.router.navigate(['/exams/results'])
    }
  }

  submitForGrading() {
    this.isLoading = true;
    this.subscriptions.push(this.examService.gradeExam(this.examId, this.answerKey, this.started).subscribe(response => this.reportGrade(response)))
  }

    // if mobile -> true
    checkScreenSize() {
      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if (w  < 769) {
        return true;
      }
      return false;
    }
  
    isEven(n: any) {
      return n % 2 === 0;
    }

}
