import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  getConfiguration(key) {
    return this.http.get<any>(API_URL + '/configuration/' + key);
  }

  getFullConfig() {
    return this.http.get<any>(API_URL + '/configuration');
  }

  updateConfiguration(key, value) {
    return this.http.post<any>(API_URL + '/configuration/' + key, {value});
  }

  getLandingPage() {
    return this.http.get<any>(API_URL + '/configuration/landing');
  }

  getSignUpPage() {
    return this.http.get<any>(API_URL + '/configuration/signup');
  }
}
