import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'jdg-resource-with-update',
  templateUrl: './resource-with-update.component.html',
  styleUrls: ['./resource-with-update.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ResourceWithUpdateComponent implements OnInit {

  subscriptions: Subscription[] = [];

  title: string;
  description: string;
  url: string;
  signedUrl: string;
  updatedAt: string;
  author: string;


  editMode: boolean = false;

  isDeleted: boolean = false;
  isLoading: boolean = true;

  @Input() id: number;

  constructor(private resourceService: ResourceService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.subscriptions.push(this.resourceService.getResourceById(this.id).subscribe(response => this.finishLoading(response)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onBeginUpload(event): void {
    this.isLoading = true;
  }

  onUpload(event): void {
    this.isLoading = false;
    this.url = event.originalEvent.body.data.url;
    this.signedUrl = event.originalEvent.body.data.signedUrl;
  }

  enableEditMode(): void {
    this.editMode = true;
  }

  saveChanges(): void {
    const resource = {
      title: this.title,
      description: this.description,
      url: this.url,
      signedUrl: this.signedUrl
    };
    this.isLoading = true;
    this.subscriptions.push(this.resourceService.updateResource(this.id, resource).subscribe(response => this.finishUpdating(response)));
  }

  cancelEditMode(): void {
    this.editMode = false;
  }

  finishLoading(response): void {
    this.isLoading = false;
    if (response.status === 'success') {

      this.title = response.data.title;
      this.url = response.data.url;
      this.description = response.data.description;
      this.signedUrl = response.data.signed_url;
      this.author = response.data.author.firstName + ' ' + response.data.author.lastName;
      this.updatedAt = response.data.updatedAt


    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Oops',
        detail : response.message
      });
    }
  }

  finishUpdating(response): void {
    this.isLoading = false;
    this.editMode = false;
    if (response.status === 'success') {
      this.author = response.data.author.firstName + ' ' + response.data.author.lastName;
      this.updatedAt = response.data.updatedAt;
      this.url = response.data.url;
      this.signedUrl = response.data.signed_url;
      this.title = response.data.title;
      this.description = response.data.description;

      this.messageService.add({
        severity: 'success',
        summary : 'Success',
        detail : response.message
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Oops',
        detail : response.message
      });
    }
  }

  deleteResource(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + this.title,
      accept: () => {
        this.isLoading = true;
        this.subscriptions.push(this.resourceService.deleteResourceById(this.id).subscribe(response => this.handleDelete(response)));
      }
    })
  }

  handleDelete(response): void {
    this.isLoading = false;
    this.isDeleted = true;
  }


}
