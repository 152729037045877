import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'jdg-public-judges',
  templateUrl: './public-judges.component.html',
  styleUrls: ['./public-judges.component.css'],
  providers : [MessageService]
})
export class PublicJudgesComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;

  judges: any[];

  constructor(private userService: UserService, private messageService: MessageService, private loginService: LoginService) { }

  ngOnInit() {
    this.loginService.hasPermission('edit_users').then( permission => {
      if (permission) {
        this.loadAllJudges();
      } else {
        this.loadRoster();
      }
    });  
  }

  loadRoster(): void {
    this.subscriptions.push(this.userService.getJudgeRoster().subscribe(result=> this.finishLoading(result)));
  }

  finishLoading(response: any): void {
    this.isLoading = false;
  	if (response.status === 'success') {
      this.judges = response.data;
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary : 'Oops',
        detail : response.message
      });
    }
  }

  loadAllJudges(): void {
    this.subscriptions.push(this.userService.getUsersPaginated(1).subscribe(result => this.finishLoading(result)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

}
