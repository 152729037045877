import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResourceService } from '../services/resource.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'jdg-manage-resources',
  templateUrl: './manage-resources.component.html',
  styleUrls: ['./manage-resources.component.css']
})
export class ManageResourcesComponent implements OnInit {

  isLoading: boolean = true;
  subscriptions: Subscription[] = [];
  resources: any[] = [];
  constructor(private resourceService: ResourceService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    let id = this.activatedRoute.snapshot.params.id;
    console.log(id);
    this.subscriptions.push(this.resourceService.getResourcesByCategory(id).subscribe(response => this.loadResources(response)));
  }

  loadResources(resourceResponse: any): void {
    this.isLoading = false;
    if (resourceResponse.status === 'success') {
      this.resources = resourceResponse.data;
    } else {
      
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

}
