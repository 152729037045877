import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private data: any = {}

  constructor() { }

  set(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key];
  }

  clear() {
    this.data = {};
  }

  remove(key) {
    delete this.data[key];
  }
}
