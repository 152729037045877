import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  async getAllNewsPosts(): Promise<any> {
    return this.http.get(API_URL + '/news').toPromise();
  }

  async getNewsPostById(id: Number): Promise<any> {
    return this.http.get(API_URL + '/news/' + id).toPromise();
  }

  async createNewsPost(newsPost: any): Promise<any> {
    return this.http.post(API_URL + '/news', newsPost).toPromise();
  }

  async deleteNewsPost(id: Number): Promise<any> {
    return this.http.delete(API_URL + '/news/' + id).toPromise();
  }

  async updateNewsPost(id: Number, newsPost: any): Promise<any> {
    return this.http.post(API_URL + '/news/' + id, newsPost).toPromise();
  }
}
