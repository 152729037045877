import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExamService } from '../services/exam.service';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'jdg-question-editor',
  templateUrl: './question-editor.component.html',
  styleUrls: ['./question-editor.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class QuestionEditorComponent implements OnInit {

  @Input() id: number = -1;
  @Input() examId: number;
  @Output() updatedQuestion = new EventEmitter<any>();

  body: string;



  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  readyToSubmit: boolean = false;
  create: boolean = true;
  answers: any[];

  unsavedAnswers: any[] = [];

  constructor(private examService: ExamService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.create = this.id == -1;
    if (!this.create) {
      this.subscriptions.push(this.examService.loadQuestionById(this.id).subscribe(response => this.loadQuestion(response)));
    } else {
      this.isLoading = false;
    }
  }

  loadQuestion(response: any): void {
    this.isLoading = false;
  	if (response.status === 'success') {
      this.body = response.data.body;
      this.answers = response.data.answers;
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary : 'Oops!',
        detail: response.message
      });
    }
  }

  createQuestion(): void {
    const question = {
      body: this.body,
      examId: this.examId
    }
    this.subscriptions.push(this.examService.createQuestion(question).subscribe(result => this.finishCreate(result)));
  }

  updateQuestion(): void {
    const question = {
      body: this.body,
    }
    this.subscriptions.push(this.examService.updateQuestion(this.id, question).subscribe(result => this.finishUpdate(result)));
  }

  finishCreate(response: any): void {
    this.isLoading = false;
  	if (response.status === 'success') {
      this.updatedQuestion.emit({status: 'success'})
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary : 'Oops!',
        detail: response.message
      });
    }
  }

  finishUpdate(response: any): void {
    if (response.status === 'success') {
      this.updatedQuestion.emit({status: 'success'})
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Oops!',
        detail : response.message
      })
    }
  }

  addAnswer(): void {
    this.unsavedAnswers.push({});
  }

  answerUpdated(event: any): void {
    this.reloadQuestion();
  }

  reloadQuestion(): void {
    this.isLoading = true;
    this.unsavedAnswers = [];
    this.subscriptions.push(this.examService.loadQuestionById(this.id).subscribe(response => this.loadQuestion(response)));
  }

  deleteQuestion(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this question?',
      accept: () => {
        this.subscriptions.push(this.examService.deleteQuestion(this.id).subscribe(response => this.finishUpdate(response)));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
