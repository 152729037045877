import { Component, OnInit, ViewChild} from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { ExamService } from '../services/exam.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../services/shared-data.service';
import { FormBuilder, Form, FormGroup, Validators, NgForm } from '@angular/forms';
import { PasswordValidation } from '../validators/password-validator';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'jdg-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class SignupComponent implements OnInit {

  @ViewChild('signupForm', {static: false}) ngForm:NgForm;
  tocValid: boolean;
  terms_and_conditions_us: string;
  terms_and_conditions_na: string;
  isLoading: boolean = true;

  subscriptions: Subscription[] = [];
  error: any = {};
  examResults: any;
  score: number;
  time: number;
  examToken: string;
  public_list: boolean;

  judgeForm: FormGroup;

  // start country dropdown
  country_list: string[] = [
    "Antigua & Barbuda",
    "Argentina",
    "Aruba",
    "Bahamas",
    "Barbados",
    "Belize",
    "Bolivia",
    "Brazil",
    "Canada",
    "Cayman Islands",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "El Salvador",
    "French Guiana",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guyana",
    "Haiti",
    "Honduras",
    "Jamaica",
    "Martinique",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Paraguay",
    "Peru",
    "Puerto Rico",
    "Saint Barthélemy",
    "St. Kitts & Nevis",
    "St. Lucia",
    "St. Vincent and the Grenadines",
    "Suriname",
    "Trinidad & Tobago",
    "Turks & Caicos Islands",
    "United States",
    "Uruguay",
    "Venezuela",
    "Virgin Islands"
  ];

  show_us_toc: boolean = false;
  country_selected: boolean = false;
  selected_country: string = '';

  constructor(private configurationService: ConfigurationService,
    private examService: ExamService,
    private userService: UserService,
    private loginService: LoginService,
    private sharedService : SharedDataService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router) { }

  ngOnInit() {
    this.loadForm();
    this.tocValid = false;
    this.examToken = this.sharedService.get('examToken');
    this.subscriptions.push(this.configurationService.getSignUpPage().subscribe(result => this.loadConfigurationData(result)));

    // clear the exam token as if they leave this page they cannot reuse that token
    this.sharedService.remove('examToken');
  }

  loadConfigurationData(result: any): void {
    if (result.status === 'success') {
      this.terms_and_conditions_us = result.data.terms_and_conditions_us;
      this.terms_and_conditions_na = result.data.terms_and_conditions_na;
    }
    this.subscriptions.push(this.examService.getExamResults(this.examToken).subscribe(result => this.loadExamInfo(result)));
  }

  loadExamInfo(result: any): void {
    this.isLoading = false;
    if (result.status === 'success') {
      this.examResults = result.data;

      this.score = Math.round((this.examResults.correct / this.examResults.total) * 100);
      this.time = Math.round(this.examResults.time_taken / (1000 * 60));
    } else {
      this.error = {
        exists : true,
        message : result.message
      };
    }
  }

  loadForm(): void {
    this.judgeForm = this.formBuilder.group({
      firstName : ['', Validators.required],
      lastName : ['', Validators.required],
      preferred_name : [''],
      preferred_pronoun : [''],
      kcgn : ['', Validators.required],
      email : ['', [Validators.required, Validators.email]],
      address : ['', Validators.required],
      address2 : [''],
      password : ['', Validators.required],
      verifyPassword : ['', Validators.required],
      city : ['', Validators.required],
      state : ['', Validators.required],
      postal : ['', Validators.required],
      country : [''],
      phone : ['', Validators.required],
      birthday : ['', Validators.required],
      public_list : [''],
      shirt_size : ['', Validators.required],
      home_store: ['', Validators.required],
      home_store_location: ['', Validators.required],
      special_skills: [''],
      lang_en: [''],
      lang_es: [''],
      lang_pt: [''],
      lang_other: [''],
      tier_one: [''],
      tier_two: [''],
      tier_three: ['']
    }, {
      validators : PasswordValidation.MatchPassword
    });
  }

  submit(judgeData): void {
    this.isLoading = true;

    judgeData.claim_token = this.examToken;
    judgeData.termsAccepted = this.tocValid;

    this.subscriptions.push(this.userService.signupUser(judgeData).subscribe(result => this.verifyUserCreated(result)));
  }

  verifyUserCreated(signupResponse: any): void {
    this.isLoading = false;
    if (signupResponse.status === 'success') {
      this.loginService.persistLogin(signupResponse);
      this.router.navigate(['/welcome']);
    } else {
      this.messageService.add({severity: 'error', summary: 'Error', detail: signupResponse.message});
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

  onCountrySelected(value: string): void {
    this.selected_country = value;
    this.country_selected = true;
    this.show_us_toc = value === 'United States';
  }

  warnSuspended() {
    this.confirmationService.confirm({
      message: 'Suspended Persons or persons who have previously been removed from the Konami Judge Program are not eligible to register in the Konami Judge Program. If you are currently Suspended from Konami Organized Play, or have been removed from the Konami Judge Program and you complete a new registration, that registration will be voided.',
      acceptLabel: 'Proceed',
      rejectLabel: 'Cancel',
      accept: () => {
        this.ngForm.ngSubmit.emit();
      }
    })
  }

}
