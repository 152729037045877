import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { ExamService } from '../services/exam.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jdg-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.css']
})
export class GradeComponent implements OnInit {

  subscriptions: Subscription[] = [];
  examToken: string;
  isLoading: boolean = true;
  error: any = {};
  examResults: any = {};

  score: number;
  time: number;

  constructor(private sharedDataService: SharedDataService, private examService: ExamService, private router: Router) { }

  ngOnInit() {
    console.log('We\'re in here');
    this.examToken = this.sharedDataService.get('examToken');
    this.subscriptions.push(this.examService.getExamResults(this.examToken).subscribe(result => this.loadResults(result)));
  }

  ngOnDestroy() {
    // unsubscribe all services
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  loadResults(gradeResponse) {
    this.isLoading = false;
    if (gradeResponse.status === 'success') {
      console.log(gradeResponse.data);
      this.examResults = gradeResponse.data;

      this.score = Math.round((this.examResults.correct / this.examResults.total) * 100);
      this.time = Math.round(this.examResults.time_taken / (1000 * 60));
    } else {
      this.error = {
        exists : true,
        message : gradeResponse.message
      };
    }
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

}
