import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jdg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: any;
  loginSubmitted = false;
  passwordInvalid = false;
  emailInvalid = false;
  isLoggingIn = false;
  returnUrl = '';

  subscriptions: Subscription[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private loginService: LoginService) { }

  ngOnInit() {
    this.user = { email: '', password: ''};
    this.returnUrl = this.route.snapshot.queryParams['ref'] || '/';
  }

  attemptLogin() {
    this.loginSubmitted = true;
    this.isLoggingIn = true;
    this.passwordInvalid = false;
    this.emailInvalid = false;

    this.subscriptions.push(this.loginService.doLogin(this.user).subscribe(result => this.processLoginResult(result)));
  }

  processLoginResult(result: any): void {
    this.isLoggingIn = false;
    if (result.status === 'fail') {
      if (result.message === 'email') {
        this.emailInvalid = true;
        return;
      } else if (result.message === 'password') {
        this.passwordInvalid = true;
        return;
      } else {
        // unknown error?
      }
    }

    this.loginService.persistLogin(result.data);
    this.router.navigate([this.returnUrl]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  toLanding(): void { 
    this.router.navigate(['/']);
  }

}
