import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'jdg-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.css']
})
export class CreateNewsComponent implements OnInit {

  body: string;
  title: string;
  isLoading: boolean = false;
  constructor(private newsService: NewsService, private router: Router) { }

  ngOnInit() {
  }

  async createNewsPost(): Promise<void> {
    this.isLoading = true;
    await this.newsService.createNewsPost({title: this.title, body: this.body});
    this.router.navigate(['/']);
  }

  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w < 769) {
      return true;
    }
    return false;
  }

}
