import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';
import { LoginService } from '../services/login.service';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'jdg-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  isLoggedIn: boolean = false;
  user: any = {};
  readyToSubmit: boolean = false;
  configuration: any = {};
  welcomeBack:string = "";
  news: any[] = [];

  constructor(private configService: ConfigurationService, private loginService:LoginService, private newsService: NewsService) { }

  ngOnInit() {
    this.subscriptions.push(this.configService.getLandingPage().subscribe(response => this.finishLoading(response)));
  }


  finishLoading(response: any): void {
    	if (response.status === 'success') {
        this.configuration = response.data;
        console.log(JSON.stringify(response));
        this.subscriptions.push(this.loginService.getLoggedInUser().subscribe(resp => this.updateOptions(resp)));
      }
      else {
        console.log(response.message);
      }
  }

  async updateOptions(loginResponse: any): Promise<void> {
    if (loginResponse.status === 'success') {
      this.isLoggedIn = true;
      this.user = loginResponse.data;
      this.welcomeBack = "Welcome back, <strong>" + this.user.firstName + "</strong>!";
      this.welcomeBack += "<br>Please use the sidebar to navigate the portal!"

      //load news posts
      const articleResponse = await this.newsService.getAllNewsPosts();
      this.news = articleResponse.data;
    } else {
      this.isLoggedIn = false;
      this.welcomeBack = "";
    }

    this.isLoading = false;
  }

  // if mobile -> true
  checkScreenSize() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w  < 769) {
      return true;
    }
    return false;
  }

  isEven(n: any) {
    return n % 2 === 0;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
