import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'jdg-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  @Input() question: any;
  @Input() number: number;
  @Output() answered = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  answerQuestion(value) {
    let answerKey = { question : this.question.id, answer : value };
    this.answered.emit(answerKey);
  }

}
